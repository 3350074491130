import './config.js';
import { AuthenticationDetails, CognitoUserAttribute, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

function getUserPool(){
  const poolData = {
    UserPoolId: window._config.cognito.userPoolId,
    ClientId: window._config.cognito.userPoolClientId
  };
  const userPool = new CognitoUserPool(poolData);
  return userPool;
}

export function signUp(username, password, attributes, callable){
  const clientMetadata = {
    site: window._config.site_name
  };

  let attributeList = [];

  for (const attribute in attributes){
    let name;
    if (attribute == 'email' || attribute == 'name'){
      name = attribute;
    } else {
      name = `custom:${attribute}`;
    }
    attributeList.push(new CognitoUserAttribute({Name: name, Value: attributes[attribute]}));
  }

  const userPool = getUserPool();
  userPool.signUp(username, password, attributeList, null, callable, clientMetadata);
}

export function confirmEmail(username, code, callable){
  const userData = {
    Username: username,
    Pool: getUserPool(),
  };
  const clientMetadata = {
    site: window._config.site_name
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmRegistration(code, true, callable, clientMetadata);
}

export function signIn(username, password, url, successCallable, failureCallable){
  const userData = {
    Username: username,
    Pool: getUserPool()
  };
  const authenticationData = {
    Username: username,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: successCallable,
    onFailure: err => failureCallable(err)
  });
}

export function forgotPassword(username, successCallable, failureCallable){
  const userData = {
    Username: username,
    Pool: getUserPool()
  };
  const clientMetadata = {
    site: window._config.site_name
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.forgotPassword({
    onSuccess: successCallable,
    onFailure: err => failureCallable(err)
  }, clientMetadata);
}

export function confirmPassword(username, code, password, successCallable, failureCallable){
  const userData = {
    Username: username,
    Pool: getUserPool()
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmPassword(code, password, {
    onSuccess: successCallable,
    onFailure: (err) => failureCallable(err)
  });
}

export function signOut(){
  const userPool = getUserPool();
  userPool.getCurrentUser().signOut();
}

export function getSession(callable, errorCallable){
  const userPool = getUserPool();
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    return cognitoUser.getSession((err, session) => {
      if (err) {
        console.error(JSON.stringify(err));
        if (errorCallable){
          return errorCallable();
        }
      }

      window._token = session.getIdToken().getJwtToken();

      // If this is the first time we've gotten a session, setup periodic
      // refresh of the cookie
      if (typeof window._config.cookieRefresh == 'undefined'){
        getCookie();

        window._config.cookieRefresh = setInterval(function(){
          getSession(getCookie)
        }, window._config.cookie_refresh_interval_ms);
      }

      if (callable){
        return callable();
      } else {
        return window._token;
      }
    });
  } else {
    // APIG and Cognito require that we send a value in the Authorization
    // header in order for the custom authorizer to work. However, we also
    // use this as a simple true/false test to see if the user is logged
    // in.
    window._token = false;

    if (callable){
      return callable();
    } else {
      return window._token;
    }
  }
}

export function getUsernameFromApi(callable) {
  fetch(window._config.apiUrl + '/cookie', {
    credentials: 'include',
    headers: {
      'Authorization': getSession()
    }
  }).then(response => {
    response.json().then(body => {
      if (body.username){
        localStorage.setItem('username', body.username);
      }
    }).then(() => {
      if (callable){
        callable();
      }
    });
  });
}

export function getUsername() {
  const userPool = getUserPool();
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    if (!localStorage.getItem('username')){
      console.error('username not known');
    } else {
      return localStorage.getItem('username');
    }
  }
}

function getCookie(){
  fetch(window._config.apiUrl + '/cookie', {
    credentials: 'include',
    headers: {
      'Authorization': window._token
    }
  }).then(response => {
    if (!response.ok){
      console.error(`url: ${response.url}`);
      console.error(`status: ${response.status}`);
      console.error(`statusText: ${response.statusText}`);
      console.error(`headers: ${JSON.stringify(response.headers)}`);
    }
  });
}
